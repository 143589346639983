import React from 'react';
import { BrowserRouter, Switch, Redirect } from 'react-router-dom';
import 'services/serviceWorker/swConfig';

import Tables from 'views/admin/Tables';
// public
import Login from 'views/auth/Login';
import Spots from 'views/admin/Spots';
import ForgotPassword from 'views/auth/ForgotPassword';

import Profile from 'views/admin/Profile';
import Users from 'views/admin/Users';

// route
import Route from './Route';
import Dashboard from 'views/admin/Dashboard';

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        {/* public routes */}
        <Route path="/" exact component={Login} />
        <Route path="/forgotPassword" component={ForgotPassword} />

        {/* private admin */}
        <Route isPrivate path="/admin/users/:role" component={Users} />
        <Route isPrivate path="/admin/profile" exact component={Profile} />
        <Route isPrivate path="/admin/spots" exact component={Spots} />
        <Route isPrivate path="/operator/spots" exact component={Spots} />

        <Route isPrivate path="/admin/dashboard" component={Dashboard} />

        {/* private demo */}
        <Route isPrivate path="/admin/tables" component={Tables} />

        {/* redirect */}
        <Redirect from="*" to="/" />
      </Switch>
    </BrowserRouter>
  );
}
