import React, { useState } from 'react';
import { FiCheckCircle, FiPlus, FiTrash, FiXCircle } from 'react-icons/fi';
import { FaEye } from 'react-icons/fa';
import { useParams } from 'react-router-dom';

import useDelete from 'services/hooks/useDelete';
import useGetList from 'services/hooks/useGetList';
import TablePaginator from 'components/Paginator';
import { Modal } from 'components/Modals/Modal';
import PageCard from 'components/Cards/PageCard';
import { colors } from 'assets/styles/theme';
import { Container } from '../../../assets/shared/styles';
import CardTableList from 'components/Cards/CardTableList';

import ModalForm from './ModalForm';
// import SearchEngine from 'Component/Search/SearchEngine';

const Users = ({ color = 'light' }) => {
  const { role } = useParams();

  const [currentPage, setCurrentPage] = useState(1);
  const [search] = useState();
  const [openForm, setOpenForm] = useState(false);
  const [selected, setSelected] = useState({});

  // get LIST
  const {
    data: users,
    getItems,
    isLoading: isLoadingList,
    meta,
  } = useGetList({
    page: currentPage,
    route: `adm/user/${role}/list`,
    search,
  });

  // DELETE
  const { apiDelete, isLoading: isLoadingDelete } = useDelete({
    errorMessage: 'falha ao apagar usuário, tente novamente mais tarde',
    successMessage: 'usuário removido com sucesso',
    onSuccess: getItems,
  });

  const isLoading = isLoadingList || isLoadingDelete;

  return (
    <PageCard color={color}>
      <Modal
        title={`${
          selected.identifier ? `Editar ${selected.name}` : 'Novo Usuário'
        }`}
        show={openForm}
        setShow={setOpenForm}
      >
        <ModalForm
          onSuccess={() => getItems()}
          identifier={selected.identifier}
          role={role}
        />
      </Modal>
      <CardTableList title={
        role === 'admin'
          ? 'Administradores'
          : role === 'manager'
          ? 'Gerentes'
          : 'Operadores'
      } color={color}>
        <div className="flex flex-wrap float-right mb-4">
          <div className="w-full">
            <button
              type="button"
              title="Adicionar novo usuário"
              className="p-2 mr-3 auto rounded bg-black"
              onClick={() => {
                setSelected({});
                setOpenForm(true);
              }}
            >
              <FiPlus size={15} color="#fff" />
            </button>
          </div>
        </div>
        <Container>
          <table className="items-center table-auto w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th
                  className={`py-3 align-middle border border-solid text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ${
                    color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700'
                  }`}
                >
                  Nome
                </th>
                <th
                  className={`align-middle border border-solid text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ${
                    color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700'
                  }`}
                >
                  Email
                </th>
                {(role === 'operator' || role === 'manager') && (
                  <>
                    <th
                      className={`align-middle text-center border border-solid text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold ${
                        color === 'light'
                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                          : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700'
                      }`}
                    >
                      Recebe leads
                    </th>
                    <th
                      className={`align-middle text-center border border-solid text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold ${
                        color === 'light'
                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                          : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700'
                      }`}
                    >
                      Prioridade
                    </th>
                    <th
                      className={`align-middle text-center border border-solid text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold ${
                        color === 'light'
                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                          : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700'
                      }`}
                    >
                      Mínimo
                    </th>
                    <th
                      className={`align-middle text-center border border-solid text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold ${
                        color === 'light'
                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                          : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700'
                      }`}
                    >
                      Máximo
                    </th>
                    <th
                      className={`align-middle text-center border border-solid text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold ${
                        color === 'light'
                          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                          : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700'
                      }`}
                    >
                      Leads recicladas
                    </th>
                  </>
                )}
                <th
                  className={`align-middle  border border-solid text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ${
                    color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700'
                  }`}
                >
                  Ações
                </th>
              </tr>
            </thead>

            <tbody>
              {users?.map(
                ({ identifier, name, email, operatorParameters }) => (
                  <tr key={identifier}>
                    <td className=" text-xs whitespace-nowrap">
                      <span
                        className={`font-bold ${+(color === 'light'
                          ? 'text-blueGray-600'
                          : 'text-white')}`}
                      >
                        {name}
                      </span>
                    </td>
                    <td className="text-xs  font-light whitespace-nowrap">
                      <span
                        className={`${+(color === 'light'
                          ? 'text-blueGray-600'
                          : 'text-white')}`}
                      >
                        {email}
                      </span>
                    </td>
                    {(role === 'operator' || role === 'manager') && (
                      <>
                        <td className="text-center text-xs font-light whitespace-nowrap">
                          <div className="flex  justify-center">
                            {operatorParameters?.shouldReceiveLead ? (
                              <FiCheckCircle size={20} color={colors.primary} />
                            ) : (
                              <FiXCircle size={20} color={colors.red[300]} />
                            )}
                          </div>
                        </td>
                        <td className="text-center text-xs font-light whitespace-nowrap">
                          {operatorParameters?.priority}
                        </td>
                        <td className="text-center text-xs font-light whitespace-nowrap">
                          {operatorParameters?.minLeads}
                        </td>
                        <td className="text-center text-xs font-light whitespace-nowrap">
                          {operatorParameters?.maxLeads}
                        </td>
                        <td className="text-center text-xs font-light whitespace-nowrap">
                          {operatorParameters?.recycledPercentage}
                          {(operatorParameters?.recycledPercentage ||
                            operatorParameters?.recycledPercentage === 0) &&
                            '%'}
                        </td>
                      </>
                    )}

                    <td className="flex py-2 whitespace-nowrap">
                      <button
                        type="button"
                        className="mr-2 p-2 rounded bg-lightBlue-500"
                        onClick={() => {
                          setSelected({ identifier, name });
                          setOpenForm(true);
                        }}
                      >
                        <FaEye size={15} color="#fff" />
                      </button>

                      <button
                        type="button"
                        className="p-2 rounded bg-red-500"
                        onClick={() => {
                          apiDelete({
                            name,
                            route: `/adm/user/${identifier}/remove`,
                          });
                        }}
                      >
                        <FiTrash size={15} color="#fff" />
                      </button>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </Container>
        <TablePaginator
          data={users}
          emptyMessage="Sem informações para listar"
          isLoading={isLoading}
          meta={meta}
          setCurrentPage={setCurrentPage}
        />
      </CardTableList>
    </PageCard>
  );
};

export default Users;
