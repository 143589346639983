import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { ClipLoader } from 'react-spinners';
import { useDispatch, useSelector } from 'react-redux';

import api from 'services/api';
import { getValuesFormatted } from 'utils/format/formikValues';
import { removeFilter, updateFilter } from 'store/modules/filter/actions';
import Select from 'components/Forms/Select';
import ConsultationByPeriod from 'components/Forms/Period';
import Card from 'components/Cards/Card';
import { useProfile } from 'services/profile/getProfile';
import PeriodTabs from './PeriodTabs';
import { readable } from '../../utils/status';
import DatePicker from 'components/Forms/DatePicker';
import ReactTooltip from 'react-tooltip';

const SearchEngine = ({ role, fields, meta }) => {
  // format fields
  const fieldsValue = fields.reduce((acc, v) => ({ ...acc, [v]: true }), {});
  const [statusList, setStatusList] = useState();
  const [backgroundOptions, setBackgroundOptions] = useState([]);
  const [period, setPeriod] = useState();
  const [openTabTime, setOpenTabTime] = useState(1);
  const [operators, setOperators] = useState();
  const { isManager } = useProfile();

  const arrayField = [];

  const dispatch = useDispatch();
  const filter = useSelector((state) => state.filter.value);

  const setSearch = (values) => {
    dispatch(updateFilter(values));
  };

  useEffect(() => {
    ReactTooltip.rebuild();
    if (fieldsValue.operator && role !== "ROLE_SPOT_GENERATOR") {
      api
        .get('/adm/user/spot-generator/list?page_size=999999999')
        .then((res) => {
          setOperators(
            res.data?.data?.map((op) => ({
              label: op.name,
              value: op.identifier,
            }))
          );
        });
    }
    if (fieldsValue.background) {
      api.get('/spot-background/list')
      .then(res => {
        setBackgroundOptions(res.data.map(background => {
          return {
            value: background.id,
            label: background.title,
          }
        }))
      })
    }
    setStatusList(
      Object.entries(readable).map(([key, value]) => ({
        label: value,
        value: key,
      }))
    );
  }, []);

  return (
    <Card color="light">
      <ReactTooltip />
      <Formik
        enableReinitialize
        initialValues={{
          search: filter.search || '',
          title: filter.title || '',
          voiceGenre: filter.voiceGenre || '',
          length: filter.length || '',
          background: filter.background || '',
          text: filter.text || '',
          status: filter.status || '',
          user: filter.user || '',
          startsAt: filter.startsAt || '',
          endsAt: filter.endsAt || '',
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
          }, 1000);
          const auxValues = getValuesFormatted({ ...values, ...period });

          arrayField.forEach((field) => {
            if (auxValues[field]) {
              auxValues[field] = [auxValues[field]];
            }
          });
          setSearch(auxValues);
        }}
      >
        {({
          values,
          isSubmitting,
          handleChange,
          resetForm,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <form
            autoComplete="off"
            className=" flex flex-col  w-full"
            onSubmit={handleSubmit}
          >
            <div className="mt-4 mb-2 flex flex-wrap self-stretch w-full">
              {fieldsValue?.search && (
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="search"
                    >
                      Busca geral
                      <input
                        id="search"
                        name="search"
                        value={values.search}
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </label>
                  </div>
                </div>
              )}
              {fieldsValue?.title && (
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="title"
                    >
                      Título
                      <input
                        name="title"
                        value={values.title}
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </label>
                  </div>
                </div>
              )}
              {fieldsValue?.text && (
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="text"
                    >
                      Texto
                      <input
                        name="text"
                        value={values.text}
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </label>
                  </div>
                </div>
              )}
              {fieldsValue?.tags && (
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="tags"
                      data-tip="Etiquetas são formas de organizar e categorizar os spots de forma simples. Escreva separando por ponto e virgula. ex: pop;rock"
                      >
                        Etiquetas (?)
                    </label>
                    <input
                      name="tags"
                      value={values.tags}
                      type="tags"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
              )}
              {fieldsValue?.voiceGenre && (
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="voiceGenre"
                    >
                      Tipo de voz
                      <Select
                        handleBlur={handleBlur}
                        name="voiceGenre"
                        placeholder=""
                        setFieldValue={setFieldValue}
                        value={values.voiceGenre}
                        options={[
                          { value: 'F', label: 'Feminina' },
                          { value: 'M', label: 'Masculina' },
                        ]}
                      />
                    </label>
                  </div>
                </div>
              )}
              {fieldsValue?.length && (
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <span
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="length"
                    >
                      Duração (segundos)
                      <Select
                        handleBlur={handleBlur}
                        name="length"
                        placeholder=""
                        setFieldValue={setFieldValue}
                        value={values.length}
                        options={[
                          { value: '30', label: '30s' },
                          { value: '45', label: '45s' },
                          { value: '60', label: '60s' },
                        ]}
                      />
                    </span>
                  </div>
                </div>
              )}
              {fieldsValue?.background && (
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="background"
                    >
                      Trilha
                      <Select
                        handleBlur={handleBlur}
                        name="background"
                        placeholder=""
                        setFieldValue={setFieldValue}
                        value={values.background}
                        options={backgroundOptions}
                      />
                    </label>
                  </div>
                </div>
              )}
              {fieldsValue?.status && (
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <span
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="status"
                    >
                      status
                      <Select
                        // isMulti
                        name="status"
                        placeholder=""
                        value={values.status}
                        className="mt-1"
                        options={statusList}
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        menuPortalTarget
                      />
                    </span>
                  </div>
                </div>
              )}
              {fieldsValue?.operator && operators && (
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <span
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="user"
                    >
                      Operadores
                      <Select
                        isMulti
                        name="user"
                        placeholder=""
                        value={values.user}
                        className="mt-1"
                        options={operators}
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        menuPortalTarget
                      />
                    </span>
                  </div>
                </div>
              )}
              {fieldsValue?.date && (
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <span
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="user"
                    >
                      Período
                      <ConsultationByPeriod
                        search={period}
                        setSearch={(e) => {
                          delete period?.interval;
                          setPeriod(e);
                        }}
                      />
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className="flex  justify-between">
              <p className="self-end block uppercase text-blueGray-500 text-xs font-bold ">
                {meta && (
                  <>
                    Mostrando {meta?.item_count} de {meta?.total_count}
                  </>
                )}
              </p>

              <div>
                <button
                  className="bg-red-400 text-white  text-xs font-bold uppercase px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => {
                    resetForm();
                    if (fieldsValue?.period) {
                      dispatch(updateFilter({ interval: 'today' }));
                    } else {
                      dispatch(removeFilter());
                    }
                    setOpenTabTime(1);
                  }}
                >
                  Limpar filtros
                </button>
                <button
                  disabled={isSubmitting}
                  className="bg-lightBlue-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                  onClick={handleSubmit}
                >
                  {isSubmitting ? (
                    <ClipLoader size={15} color="#fff" />
                  ) : (
                    'Filtrar'
                  )}
                </button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </Card>
  );
};

export default SearchEngine;
